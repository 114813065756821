<template>
  <auth>
    <template #auth-title>
      Sign In
    </template>

    <template #form>
      <b-field
        label="Email"
        class="mb-4"
      >
        <b-input
          v-model="auth.email"
          icon="email"
          type="text"
          placeholder="max.mustermann@example.de"
          @keyup.native.enter="sendConfirmationCode()"
        />
      </b-field>
      <b-field label="Password">
        <b-input
          v-model="auth.password"
          icon="lock"
          type="password"
          password-reveal
          placeholder="************"
          @keyup.native.enter="sendConfirmationCode()"
        />
      </b-field>
    </template>

    <template #auth-additional-options>
      <div class="column is-6">
        <b-checkbox
          v-model="auth.remember_me"
          size="is-small"
          class="has-text-grey"
        >
          Remember this computer
        </b-checkbox>
      </div>
      <div class="column is-6">
        <router-link
          class="is-pulled-right is-size-7"
          to="/password-forgot"
        >
          Forgot password?
        </router-link>
      </div>
    </template>

    <template
      v-if="isSignInFailed"
      #error-message
    >
      Username and/or password are incorrect. Please try again.
    </template>

    <template #action-button>
      <b-button
        expanded
        type="is-primary"
        @click="sendConfirmationCode()"
      >
        Sign in
      </b-button>
    </template>

    <template #info>
      <a
        href="https://nuclicore.com#demo"
        target="_blank"
      >Don't have an account yet? Send us a demo request.</a>
    </template>
  </auth>
</template>

<script>
import { useRouter } from '@/hooks/vueRouter';
import { sendConfirmationCodeService } from '@/services/auth-service/authRequests';
import { reactive, ref } from '@vue/composition-api';
import Auth from './../components/Auth';
import {useSession} from '@/hooks/vueSession';
import {useCookies} from '@/hooks/vueCookies';
import {useVueJwtDecode} from '@/hooks/vueJwtDecode';

export default {
    components: {
        Auth
    },
    setup () {
        // compose hooks
        const router = useRouter();
        const session = useSession();
        const cookies = useCookies();
        const jwtDecode = useVueJwtDecode();

        /***** Confirmation code logic start *****/
        const auth = reactive({
            email: '',
            password: '',
            remember_me: false
        });
        const isSignInFailed = ref(false);
        const sendConfirmationCode = async () => {
            try {
                isSignInFailed.value = false;
                const { data: { data }} = await sendConfirmationCodeService({
                    client_id: auth.email,
                    client_secret: auth.password
                });
                if (data.two_factor) {
                    localStorage.setItem('client_id', auth.email);
                    localStorage.setItem('client_secret', auth.password);
                    router.push('/login-confirm');
                } else {
                    session.start();
                    session.set('jwt', data.token);
                    const decodedJwt = jwtDecode.decode(data.token);
                    session.set('role', decodedJwt.isr);
                    session.set('email', decodedJwt.ist);
                    session.set('id', decodedJwt.iid);
                    session.set('full_name', decodedJwt.ifn);
                    session.set('profile_pic', decodedJwt.ipp);
                    cookies.set('shared_session', data.token, 86400 * 360, '/', 'nuclicore.com');
                    cookies.set('shared_session_domain', window.location.protocol + '//' + window.location.hostname, 86400 * 360, '/', 'nuclicore.com');
                    localStorage.removeItem('client_id');
                    localStorage.removeItem('client_secret');
                    await router.push('/applications');
                }
              
            } catch (err) {
                console.error(err);
                isSignInFailed.value = true;
            }
        };
        /** logic exposed to template */
        const exposedConfirmationCodeLogic = {
            auth,
            isSignInFailed,
            sendConfirmationCode
        };
        /***** Confirmation code logic end *****/

        return {
            ...exposedConfirmationCodeLogic
        };
    }
};
</script>
