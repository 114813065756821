// @ts-check
import { authInterceptor, createHttpServiceConfig } from '@/services/httpFactories';
import { AUTH_SERVICE_ENDPOINT, CONFIRMATION_CODE_RESOURCE_PATH, FORGOT_PASSWORD_RESOURCE_PATH, RESET_PASSWORD_RESOURCE_PATH, TOKEN_RESOURCE_PATH } from './authServiceConstants';

const authServiceConfig = createHttpServiceConfig(AUTH_SERVICE_ENDPOINT, '');

//-- request interceptors --//
authServiceConfig.addRequestInterceptor(authInterceptor);

/**
 * @param {import('../../types/authTypes').IForgotPassword} data 
 */
export const forgotPasswordService = (data) => authServiceConfig.http({
    method: 'POST',
    url: FORGOT_PASSWORD_RESOURCE_PATH,
    data
});

/**
 * @param {import('../../types/authTypes').IResetPassword} data
 */
export const resetPasswordService = (data) => authServiceConfig.http({
    method: 'POST',
    url: RESET_PASSWORD_RESOURCE_PATH,
    data
});

/**
 * @param {import('../../types/authTypes').IToken} data
 */
export const signInService = (data) => authServiceConfig.http({
    method: 'POST',
    url: TOKEN_RESOURCE_PATH,
    data
});

/**
 * @param {import('../../types/authTypes').IConfirmationCode} data 
 */
export const sendConfirmationCodeService = (data) => authServiceConfig.http({
    method: 'POST',
    url: CONFIRMATION_CODE_RESOURCE_PATH,
    data
});
