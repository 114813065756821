<template>
  <div class="columns is-mobile is-centered">
    <div class="column is-one-quarter-widescreen is-one-third-desktop is-half-tablet is-shadowless is-full-mobile box auth-box py-6 px-6">
      <div class="has-text-centered mb-2">
        <figure class="image is-inline-block is-8">
          <b-image :src="require('@/assets/logo.svg')" />
        </figure>
      </div>

      <div class="has-text-centered mb-5">
        <h1 class="is-size-5">
          <slot name="auth-title" />
        </h1>
      </div>

      <div class="my-3">
        <b-message
          type="is-success"
          size="is-small"
        >
          <slot name="success-message" />
        </b-message>
      </div>

      <div class="my-3">
        <b-message
          type="is-danger"
          size="is-small"
        >
          <slot name="error-message" />
        </b-message>
      </div>

      <div class="mb-5">
        <slot name="form" />
      </div>

      <div class="columns">
        <slot name="auth-additional-options" />
      </div>

      <div class="mb-5">
        <slot name="action-button" />
      </div>

      <div class="is-size-7 has-text-centered">
        <slot name="info" />
      </div>
    </div>
  </div>
</template>

<script>
export default {

};
</script>

<style lang="scss">
.auth-box {
  margin-top: 100px;
}

.box {
  border: 1px solid #EBEBEF;
}

@media only screen and (max-width: 770px) {
  body {
    background: #FFFFFF;
  }

  .auth-box {
    margin-top: 0;
  }

  .box {
    border: none;
  }
}

</style>